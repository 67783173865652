<template>
  <section>
    <section class="fixed-amount field">
      <header class="field__title">Сумма к оплате</header>
      <div class="fixed-amount__amount"><money :value="totalAmount" /></div>
    </section>
    <ReceiptSend />
    <section class="pay-methods">
      <h2 class="order__title pay-methods__title">Способ оплаты</h2>
      <div class="pay-methods__buttons">
        <button
          v-for="pm in payMethods"
          :key="pm"
          class="pay-methods__button"
          :class="{
            active: cardPay && pm === 'card',
          }"
          @click="payment(pm)"
        >
          <img
            :src="
              require(`@/assets/img/${
                pm === 'card' && cardPay ? pm + '_white' : pm
              }.svg`)
            "
            alt
          />
          <span v-if="pm === 'card'">Карта</span>
        </button>
      </div>

      <div class="card-form" v-if="cardPay">
        <div
          id="payment-form"
          class="fns-payment-form"
          :class="{ 'fns-payment-form_hidden': !cardPay }"
        ></div>
      </div>
    </section>
    <Footer />
  </section>
</template>

<script>
import { getTemplate } from "@/api/template";
// postInvoiceNumber
import { invoicePaymentData } from "@/api/fns";
import { RECEIVE_PRODUCTS } from "@/store/mutations.type";
import { REQUEST_ORDER } from "@/store/actions.type";
import ReceiptSend from "@/components/checkout/ReceiptSend.vue";
import Footer from "@/components/Footer.vue";
import { getBlock } from "@/store/modules/menu";
import { Block } from "@/constants";
import { isApplePayAvailable } from "@/utils/apple-pay";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { validateEmail, validatePhone } from "@/utils";
export default {
  data() {
    return {
      page: null,
      cardPay: false,
      isValid: true,
      isPaymentFormVisible: false,
      phone: "",
      sendType: "email",
      langSelectorVisible: false,
      lang: "RU",
      langs: ["GR", "FR", "EN", "RU"],
      payMethod: null,
    };
  },
  components: {
    ReceiptSend,
    Footer,
  },
  computed: {
    ...mapState({
      saleInfo: (state) => state.pay.invoicePaymentData.saleInfo,
      totalAmount: (state) => state.pay.invoicePaymentData.totalAmount,
      merchantName: (state) => state.pay.invoicePaymentData.merchantName,
      invoiceNumber: (state) => state.pay.invoicePaymentData.invoiceNumber,
      inn: (state) => state.pay.invoicePaymentData.inn,
      date: (state) => state.pay.invoicePaymentData.date,
      email: (state) => state.pay.email,
      initPaymentData: (state) => state.pay.initPaymentData,
      paymentCardData: (state) => state.pay.paymentCardData,
      isReadyToGooglePay: (state) => state.pay.googlepayData.isReadyToPay,
      payResultData: (state) => state.pay.invoicePaymentData,
    }),
    ...mapGetters({
      isCardPaymentAvailable: "pay/isCardPaymentAvailable",
      isSbpPaymentAvailable: "pay/isSbpPaymentAvailable",
      isApplepayPaymentAvailable: "pay/isApplepayPaymentAvailable",
      isGooglePaymentAvailable: "pay/isGooglepayPaymentAvailable",
      pay2proPaymentUrl: "pay/pay2proPaymentUrl",
      isDesktop: "pay/isDesktop",
      showSbpQrcode: "pay/showSbpQrcode",
      paymentMethods: "paymentMethods",
    }),

    payMethods() {
      return getBlock(
        this.page,
        "checkout",
        Block.PaymentMethodsBlock
      )?.body.find((item) => item.name === "methods").value;
    },
    isApplePayAvailable() {
      return this.isApplepayPaymentAvailable && isApplePayAvailable();
    },
    isGooglePayAvailable() {
      return this.isGooglePaymentAvailable && this.isReadyToGooglePay;
    },
    isPhoneValid() {
      return this.sendType === "phone" && this.isValid;
    },
    isEmailValid() {
      return this.sendType === "email" && this.isValid;
    },
  },
  methods: {
    ...mapActions({
      requestOrder: `pay/${REQUEST_ORDER}`,
      requestPaySbp: "pay/requestPaySbp",
      requestPaymentCard: "pay/requestPaymentCard",
      startApplePay: "pay/startApplePay",
      startGooglePay: "pay/startGooglePay",
      waitForIsReadyToGooglePay: "pay/waitForIsReadyToGooglePay",
      requestInvoicePaymentData: "pay/requestInvoicePaymentData",
    }),
    ...mapMutations({
      setEmail: "pay/setEmail",
      setSuccess: "pay/setSuccess",
      receiveProducts: RECEIVE_PRODUCTS,
    }),
    selectLang: function (event) {
      const lang = event.target.value;
      this.toggleLangs();
      this.lang = lang;
    },
    toggleLangs: function () {
      this.langSelectorVisible = !this.langSelectorVisible;
    },

    validate() {
      if (this.sendType === "phone") {
        this.isValid = validatePhone("ru", this.phone);
      }
      if (this.sendType === "email") {
        this.isValid = validateEmail(this.email);
      }
      return this.isValid;
    },

    feedbackValidate() {
      const feedbackForm = document.querySelector(".feedbackForm");
      if (feedbackForm) {
        const phone = this.feedbackValue?.find(
          (item) => item.name === "phone"
        ).value;
        this.feedBackEmptyChange(validatePhone("ru", phone));
        return validatePhone("ru", phone);
      }
      return true;
    },

    async payment(pm) {
      if ([this.validate(), this.feedbackValidate()].some((item) => !item)) {
        window.scrollTo(0, 0);
        return;
      }

      if (!+this.total && !+this.totalAmount) {
        this.setTotalError(true);
        window.scrollTo(0, 0);
        return;
      }
      if (pm === "card") this.cardPay = true;

      const feedbackForm = document.querySelector(".feedbackForm");
      if (feedbackForm) {
        getBlock(
          this.pageTemplate,
          this.route.current,
          Block.FeedbackBlock
        ).body = this.feedbackValue;
      }
      try {
        const templateId = JSON.parse(localStorage.getItem("templateId"));
        await this.requestOrder({
          cardBlock: {
            ...this.page,
            template_id: templateId,
          },
          payMethod: pm,
        });
        if (pm !== "card") await this.$router.push("/checkout-success");
      } catch (e) {
        if (e === "item_not_available" || e === "all_not_available") {
          await this.$router.push(
            `/checkout-not-available?template=${this.$route.query.template}&error=${e}`
          );
        } else {
          await this.$router.push("/checkout-error");
        }
      }
    },
  },
  mounted() {
    getTemplate().then((res) => {
      this.page = res;

      let currentPage = this.$route.fullPath;
      let arr = currentPage.split("/");
      let currentLink = arr[arr.length - 1];
      let internal_id = currentLink;
      console.log(internal_id);
      invoicePaymentData(internal_id).then((res) => {
        const invoiceId = res.internal_id;
        this.requestInvoicePaymentData({ invoiceId });
      });
    });
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/components/pay-methods.scss";
</style>
